import { useThree, type ThreeEvent } from '@react-three/fiber';
import { useMemo, useState } from 'react';
import { CanvasTexture, Vector3 } from 'three';
import { useGizmoContext } from '@react-three/drei';

interface FaceConfig {
  text: string
  squareColor: string
  textColor: string
  hoverColor: string
  strokeColor: string
}

export interface CustomGizmoViewcubeProps {
  faces?: {
    right: FaceConfig
    left: FaceConfig
    top: FaceConfig
    bottom: FaceConfig
    front: FaceConfig
    back: FaceConfig
  }
  font?: string
  opacity?: number
  onClick?: (direction: Vector3) => void
}

const defaultFaceConfig: FaceConfig = {
  text: '',
  squareColor: '#f0f0f0',
  textColor: 'black',
  hoverColor: '#999',
  strokeColor: 'black'
};

const defaultFaces = {
  right: { ...defaultFaceConfig, text: 'RIGHT' },
  left: { ...defaultFaceConfig, text: 'LEFT' },
  top: { ...defaultFaceConfig, text: 'TOP' },
  bottom: { ...defaultFaceConfig, text: 'BOTTOM' },
  front: { ...defaultFaceConfig, text: 'FRONT' },
  back: { ...defaultFaceConfig, text: 'BACK' }
};

const makePositionVector = (xyz: number[]) => new Vector3(...xyz).multiplyScalar(0.38);

const corners: Vector3[] = [
  [1, 1, 1],
  [1, 1, -1],
  [1, -1, 1],
  [1, -1, -1],
  [-1, 1, 1],
  [-1, 1, -1],
  [-1, -1, 1],
  [-1, -1, -1],
].map(makePositionVector);

const cornerDimensions: [number, number, number] = [0.25, 0.25, 0.25];

const edges: Vector3[] = [
  [1, 1, 0],
  [1, 0, 1],
  [1, 0, -1],
  [1, -1, 0],
  [0, 1, 1],
  [0, 1, -1],
  [0, -1, 1],
  [0, -1, -1],
  [-1, 1, 0],
  [-1, 0, 1],
  [-1, 0, -1],
  [-1, -1, 0],
].map(makePositionVector);

const edgeDimensions = edges.map(
  (edge) => edge.toArray().map((axis: number): number => (axis === 0 ? 0.5 : 0.25)) as [number, number, number]
);

const FaceMaterial = ({
  hover,
  index,
  faceConfig,
  font = '20px Inter var, Arial, sans-serif',
  opacity = 1,
}: {
  hover: boolean
  index: number
  faceConfig: FaceConfig
  font?: string
  opacity?: number
}) => {
  const gl = useThree((state) => state.gl);

  const texture = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 128;
    canvas.height = 128;
    const context = canvas.getContext('2d');

    context.fillStyle = '#f0f0f0';
    context.fillRect(0, 0, canvas.width, canvas.height);

    const squareSize = 84;
    const squareX = (canvas.width - squareSize) / 2;
    const squareY = (canvas.height - squareSize) / 2;
    context.fillStyle = faceConfig.squareColor;
    context.fillRect(squareX, squareY, squareSize, squareSize);

    context.strokeStyle = faceConfig.strokeColor;
    context.strokeRect(0, 0, canvas.width, canvas.height);

    context.font = font;
    context.textAlign = 'center';
    context.fillStyle = faceConfig.textColor;
    context.fillText(faceConfig.text, 64, 76);

    return new CanvasTexture(canvas);
  }, [faceConfig, font]);

  return (
    <meshBasicMaterial
      map={texture}
      map-anisotropy={gl.capabilities.getMaxAnisotropy() || 1}
      attach={`material-${index}`}
      color={hover ? faceConfig.hoverColor : 'white'}
      transparent
      opacity={opacity}
    />
  );
};

const FaceCube = ({ faces = defaultFaces, font, opacity, onClick }: CustomGizmoViewcubeProps) => {
  const [hover, setHover] = useState<number | null>(null);
  const { tweenCamera } = useGizmoContext();
  const faceConfigs = [faces.left, faces.right, faces.top, faces.bottom, faces.front, faces.back];

  const handlePointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(null);
  };

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (e.face) {
      tweenCamera(e.face.normal);
      if (onClick) onClick(e.face.normal);
    }
  };

  const handlePointerMove = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(Math.floor(e.faceIndex / 2));
  };

  return (
    <mesh onPointerOut={handlePointerOut} onPointerMove={handlePointerMove} onClick={handleClick}>
      {faceConfigs.map((faceConfig, index) => (
        <FaceMaterial
          key={index}
          index={index}
          hover={hover === index}
          faceConfig={faceConfig}
          font={font}
          opacity={opacity}
        />
      ))}
      <boxGeometry />
    </mesh>
  );
};

const EdgeCube = ({
  dimensions,
  position,
  hoverColor = '#999',
  opacity = 0.6
}: {
  dimensions: [number, number, number]
  position: Vector3
  hoverColor?: string
  opacity?: number
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const { tweenCamera } = useGizmoContext();

  const handlePointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(false);
  };

  const handlePointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setHover(true);
  };

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    tweenCamera(position);
  };

  return (
    <mesh
      scale={1.01}
      position={position}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      onClick={handleClick}
    >
      <meshBasicMaterial
        color={hover ? hoverColor : 'white'}
        transparent
        opacity={opacity}
        visible={hover}
      />
      <boxGeometry args={dimensions} />
    </mesh>
  );
};

export const CustomGizmoViewcube: React.FC<CustomGizmoViewcubeProps> = (props) => {
  const hoverColor = props.faces?.front.hoverColor || '#999';

  return (
    <group scale={[60, 60, 60]}>
      <FaceCube {...props} />
      {edges.map((edge, index) => (
        <EdgeCube
          key={`edge-${index}`}
          position={edge}
          dimensions={edgeDimensions[index]}
          hoverColor={hoverColor}
        />
      ))}
      {corners.map((corner, index) => (
        <EdgeCube
          key={`corner-${index}`}
          position={corner}
          dimensions={cornerDimensions}
          hoverColor={hoverColor}
        />
      ))}
    </group>
  );
};