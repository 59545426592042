import type { CustomGizmoViewcubeProps } from './CustomGizmoViewcube';

export const customFaces: Pick<CustomGizmoViewcubeProps, 'faces'>['faces'] = {
  right: {
    text: 'RIGHT',
    squareColor: '#ff3E25',
    textColor: 'black',
    hoverColor: '#999',
    strokeColor: 'black'
  },
  left: {
    text: 'LEFT',
    squareColor: '#909090',
    textColor: 'black',
    hoverColor: '#999',
    strokeColor: 'black'
  },
  top: {
    text: 'TOP',
    squareColor: '#00ff00',
    textColor: 'black',
    hoverColor: '#999',
    strokeColor: 'black'
  },
  bottom: {
    text: 'BOTTOM',
    squareColor: '#909090',
    textColor: 'black',
    hoverColor: '#999',
    strokeColor: 'black'
  },
  front: {
    text: 'FRONT',
    squareColor: '#4050ff',
    textColor: 'black',
    hoverColor: '#999',
    strokeColor: 'black'
  },
  back: {
    text: 'BACK',
    squareColor: '#909090',
    textColor: 'black',
    hoverColor: '#999',
    strokeColor: 'black'
  }
}; 