import { Environment, GizmoHelper } from '@react-three/drei';
import { Canvas, extend, useThree } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { useCamera } from '../providers/camera';
import { useSPProject } from '../providers/spproject';
import CameraController from './camera-controller';
import DrawLines from './draw-lines';
import GridWithLabels from './grid-with-labels';
import InspectionPoints from './inspection-points';
import MeasurementHandler from './measurement/measurement';
import { CustomGizmoViewcube } from './custom-gizmo/CustomGizmoViewcube';
import { customFaces } from './custom-gizmo/gizmo-config';

extend({ Line_: THREE.Line });

const SkySphere = ({ blobUrl }) => {
  const { gl, scene } = useThree();
  const pmremGenerator = new THREE.PMREMGenerator(gl);
  const loader = new RGBELoader();
  loader.setDataType(THREE.FloatType);
  pmremGenerator.compileEquirectangularShader();

  useEffect(() => {
    loader.load(blobUrl, texture => {
      const envMap = pmremGenerator.fromEquirectangular(texture).texture;

      scene.environment = envMap;

      texture.dispose();
      pmremGenerator.dispose();
    });

    return () => {
      gl.dispose();
    };
  }, [scene, loader, pmremGenerator]);

  return null;
};

const ModelViewer: React.FC = () => {
  const { model, selectedInspection, loading } = useSPProject();
  const { roomEnvironment } = useCamera();
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  const isSingleton = window.isWebpackSingle;

  useEffect(() => {
    const fetchHDRI = async () => {

      try {
        console.log(`base64projecthdri:${window.base64projecthdri}`);
        const defaultHdriBase64 = window.base64projecthdri;
        const byteCharacters = atob(defaultHdriBase64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const blobUrl = URL.createObjectURL(blob);
        setBlobUrl(blobUrl);
      } catch (error) {
        console.error('Error fetching HDRI:', error);
      }
    };

    if (isSingleton) fetchHDRI();

    model?.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        (Array.isArray(child.material) ? child.material : [child.material]).forEach((material) => {
          material.side = THREE.DoubleSide;
        });
      }
    });

  }, [model]);

  if (loading) {
    return (
      <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spinner animation="border" variant="light" />
      </div>
    );
  }

  return (
    <Canvas id="threejs-canvas"
      style={{ width: '100%', backgroundColor: 'transparent' }}
      gl={{ antialias: true, powerPreference: 'high-performance', logarithmicDepthBuffer: true }}
      linear
    >
      {model && (
        <primitive object={model} />
      )}
      {selectedInspection != null && selectedInspection.pointOfInterests.length > 0 ? (
        <InspectionPoints inspection={selectedInspection} />
      ) : (
        <></>
      )}
      {(isSingleton && blobUrl) && (
        <SkySphere blobUrl={blobUrl} />
      )}
      {!isSingleton && <Environment preset={roomEnvironment} />}
      <CameraController model={model} />
      <GizmoHelper alignment="bottom-right" margin={[150, 100]}>
        <CustomGizmoViewcube
          faces={customFaces}
          opacity={0.9}
          onClick={(direction) => {
            console.log('Clicked direction:', direction);
          }}
        />
      </GizmoHelper>
      <GridWithLabels model={model} />
      <DrawLines />
      <MeasurementHandler />
    </Canvas>
  );
};

export default ModelViewer;
